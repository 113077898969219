<template>
  <b-card>
    <b-row>
      <b-col md="8">
        <b-button
          variant="outline-primary"
          :to="{ name: 'apps-clients-add' }"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('message.Add_Client') }}
        </b-button>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <b-form-input
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            v-model="searchQuery"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          ref="refClientListTable"
          :items="clients"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="$t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(clients)="data">
            <b-badge
              v-for="(client, index) in data.item.clients.slice(0, 2)"
              :key="index"
              class="ml-1 mb-1"
              variant="light-primary"
            >
              <!-- {{ truncateWords(client.location) }} -->
            </b-badge>

            <feather-icon
              v-if="data.item.clients.length > 2"
              badge-classes="custom_badge_settings"
              :badge="`+${data.item.clients.length - 2}`"
              icon=""
              size="1"
            />
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <ViewButton />
              <EditButton
                @clicked="
                  router.push({
                    name: 'apps-clients-edit',
                    params: { id: data.item._id },
                  })
                "
              />
              <DeleteButton
                @clicked="deleteClient(data.item._id, data.item._id, deletedSuccess)"
              />
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-between">
        <div>
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5, 10, 15, 20, 25, 30, 40, 50, 100]"
              class="w-50"
            />
          </b-form-group>
        </div>
        <div>
          <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + 1}`, to: `${clients.length + ((currentPage * perPage) - perPage)}`, total: `${totalClients}` }) }}</span>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalClients"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BBadge, BPagination, BTable } from "bootstrap-vue";

import { onUnmounted, onMounted } from "@vue/composition-api";
import store from "@/store";
import { useRouter } from "@core/utils/utils";
import EditButton from "@/views/components/Shared/Buttons/EditButton.vue";
import ViewButton from "@/views/components/Shared/Buttons/ViewButton.vue";
import DeleteButton from "@/views/components/Shared/Buttons/DeleteButton.vue";

import useClientList from "./useClientList";

import clientStoreModule from "../clientStoreModule";
import useNotifications from "@/composables/useNotifications";
import i18n from '@/libs/i18n';

export default {
  data: {
    items: [],
  },
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    ViewButton,
    DeleteButton,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const ORGANIZATION_LOCATION_STORE_MODULE_NAME = "apps-clients-list";
    const { router } = useRouter();
    const {
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      statusFilter,
      refetchData,
      deleteClient,
      resolveProcessStatusVariantAndIcon,
      clients,
    } = useClientList();

    // Register module
    if (!store.hasModule(ORGANIZATION_LOCATION_STORE_MODULE_NAME)) {
      store.registerModule(
        ORGANIZATION_LOCATION_STORE_MODULE_NAME,
        clientStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_LOCATION_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_LOCATION_STORE_MODULE_NAME);
    });

    onMounted(() => {
      fetchClients();
    });

    const deletedSuccess = (success) => {
      if (success) {
        fetchClients();
        showSuccessMessage(i18n.t('message.client_removed'));
      } else {
        showErrorMessage(i18n.t('message.something_went_wrong'));
      }
    };

    return {
      router,
      fetchClients,
      tableColumns,
      perPage,
      currentPage,
      totalClients,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refClientListTable,
      statusFilter,
      refetchData,
      deleteClient,
      deletedSuccess,
      resolveProcessStatusVariantAndIcon,
      clients
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
