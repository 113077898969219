import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { updateClient, _getClients } from '@/@core/queries/clients'
import i18n from '@/libs/i18n'

export default function useClientList() {
  const { showErrorMessage } = useNotifications()
  const refClientListTable = ref(null)
  const clients = ref([])

  // Table Handlers
  const tableColumns = [
    {
      key: 'custom_data.username',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'data.email',
      label: 'Email',
      sortable: true,
    },
    {
      key: 'custom_data.project',
      label: 'Project',
      sortable: true,
    },
    {
      key: 'actions',
    },
  ]
  const perPage = ref(10)
  const totalClients = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('username')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refClientListTable.value
      ? refClientListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalClients.value,
    }
  })

  const refetchData = () => {
    if (refClientListTable.value) {
      refClientListTable.value.refresh()
    }
  }

  const fetchClients = () => {
    store
      .dispatch('apps-clients-list/fetchClients', {
        query: _getClients,
        variables: {
          input: {
            limit: perPage.value,
            total: currentPage.value === 1,
            offset: currentPage.value * perPage.value - perPage.value,
            query: {},
            search: searchQuery.value,
            sortBy: sortBy.value,
            sortOrder: isSortDirDesc.value ? -1 : 1,
            role: 'client',
          },
        },
      })
      .then(response => {
        const {
          ClientsData: { clients: w, totalRecords: total },
        } = response.data.data || {}
        clients.value = w
        if (currentPage.value === 1) totalClients.value = total
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.err_client_list_fetch'))
      })
  }

  const deleteClient = (userId, Id, callback) => {
    store
      .dispatch('organization-location/deleteClient', {
        query: updateClient,
        variables: { query: { _id: userId }, data: { deleted: true } },
      })
      .then(() => {
        callback(true)
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.something_went_wrong'))
      })
  }

  watch([currentPage, perPage, statusFilter], () => {
    fetchClients()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchClients()
  })

  return {
    fetchClients,
    tableColumns,
    perPage,
    currentPage,
    totalClients,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClientListTable,
    clients,
    statusFilter,
    refetchData,
    deleteClient,
  }
}
